// ** Third Party Components
import styled, { css } from "styled-components";

import { NameContainer } from "components/user/Name";

export const StyledMasonryCardFooterSection = styled.div`
	position: absolute;
	display: flex;
	left: 0px;
	bottom: 0px;
	z-index: 10;
	width: 100%;
	padding: 0.5rem 0.5rem 0.25rem;
	color: ${({ theme: { colors } }) => colors.whiteAlways};
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 1) 100%
	);

	${({ asLink }) =>
		asLink &&
		css`
			margin-bottom: 0;
			&:focus,
			&:focus-within,
			&:hover {
				${NameContainer} {
					text-decoration: underline !important;
					color: ${({ theme: { colors } }) => colors.whiteAlways};
				}
			}
		`}
`;
