// ** Custom Components
import Text from "components/typography/Text";
import Loader from "components/layout/Loader";

// ** Media Components
import MediaImage from "./MediaImage";
import MediaVideo from "./MediaVideo";

// ** Styled Components
import { StyledLoadMoreItemButton } from "../styles";

// #####################################################

const Media = ({ type, ...props }) => {
	switch (type) {
		case "photo": {
			return <MediaImage {...props} />;
		}

		case "video": {
			return <MediaVideo {...props} />;
		}

		case "loading": {
			return (
				<StyledLoadMoreItemButton type="button">
					<Loader color="white" size={20} />
				</StyledLoadMoreItemButton>
			);
		}

		default:
			return <Text>Invalid Media Type: {type}</Text>;
	}
};

export default Media;

// #####################################################
