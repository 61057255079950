// ** React Imports
import { useCallback } from "react";

// ** Custom Components
import DatePresenter from "components/other/DatePresenter";
import IconContainer from "components/global/IconContainer";
import Text from "components/typography/Text";
import Block from "components/layout/Block";
import Login from "components/user/Login";
import DynamicHeartIcon from "components/icons/DynamicHeartIcon";

// ** Utils & Helpers
import pseudoFormat from "utils/pseudoFormat";

// #####################################################

export const MasonryCardFooterContent = ({
	dateAdd,
	count,
	showZeroCount,
	login,
	statusVip,
	icon,
	isUserGallery = false,
}) => {
	// ** Funkcja renderujaca 'stopkę' zdjęcia
	const renderBottomMain = useCallback(
		() =>
			isUserGallery ? (
				<Block
					flex
					row
					alignCenter
					size={"0.8rem"}
					color="whiteAlways"
					textNowrap
					ml={1}
				>
					<DynamicHeartIcon
						customMargin="0 0.25rem 0 0"
						height="11.2px"
						width="12px"
						customFill="whiteAlways"
					/>

					{count === 0 && !showZeroCount ? null : (
						<Text>{pseudoFormat(count)}</Text>
					)}
				</Block>
			) : (
				<Block size="0.9rem" color="whiteAlways" textNowrap ml={1}>
					<Text mr={1}>
						<IconContainer>{icon}</IconContainer>
					</Text>
					{count === 0 && !showZeroCount ? null : (
						<Text>{pseudoFormat(count)}</Text>
					)}
				</Block>
			),
		[]
	);

	// ** Funkcja renderujaca login użytkownika lub datę dodania zdjęcia
	const renderBottom = useCallback(
		() =>
			login && !dateAdd ? (
				<>
					<Login
						oneLine
						login={login}
						vip={statusVip}
						size="xsmall"
						style={{ color: "var(--dz-ref-color-white)" }}
					/>
					{renderBottomMain()}
				</>
			) : (
				<>
					<Block>
						{isUserGallery ? (
							<Text size={"0.8rem"} color="whiteAlways">
								<DatePresenter dateOnly date={dateAdd} />
							</Text>
						) : (
							<Text size="0.9rem" color="whiteAlways">
								<DatePresenter dateOnly date={dateAdd} />
							</Text>
						)}
					</Block>
					{renderBottomMain()}
				</>
			),
		[]
	);

	return (
		<Block flex justifyBetween fullWidth>
			{renderBottom()}
		</Block>
	);
};
