// ** React Imports
import { useContext, useMemo, memo } from "react";
import { Link } from "react-router-dom";

// ** Third Party Components
import HoverVideoPlayer from "components/video/HoverVideoPlayer";

// ** Custom Components
import { Image } from "components/media";
import Block from "../Block";

// ** Custom Hooks
import useMobileDetect from "hooks/useMobileDetectHook";

// ** Utils & Helpers
import generatePath from "utils/generatePath";

// ** Context
import authContext from "contexts/authContext";

// ** Routes
import containers from "containers";

// ** Styled Components
import { StyledMediaWrapper } from "../../media/styles";
import { StyledMasonryCardWrapper } from "./styles/MasonryCardWrapper.styled";
import { StyledMasonryCardFooterSection } from "./styles/MasonryCardFooterSection.styled";
import { MasonryCardFooterContent } from "./MasonryCardFooterContent";

// ** Icons
import { ReactComponent as HeartIcon } from "resources/icons/regular/heart.svg";

// #####################################################

const MasonryVideo = ({
	thumbs: { poster_vertical = "", preview_vertical = "" } = {},
	video: sourceFull,
	date_add,
	likes,
	login,
	status_vip,
	id,
	bottomAsLink,
	bottomAsLinkToMedia,
	album,
	width,
	height,
	iconSize = "masonry",
	type = "video",
	withoutLazyLoading = true,
	withSpinner = false,
	lastElementRef,
	minHeight,
	isUserGallery = false,
	isSquared = false,
	locationSearch = "",
}) => {
	// ** Hook useContext - Pobranie z kontekstu informacji
	// ** czy użytkownik jest zalogowany
	const isAuth = useContext(authContext);

	// ** Jeżeli zalogowany generujemy ścieżkę do filmu,
	// ** w przeciwnym wypadku ustawiamy link na "/"
	const to = useMemo(() => {
		if (isAuth) {
			const generatedPath = generatePath(containers.modal.path, {
				contentId: id,
				login: login?.toLowerCase(),
				type: type,
				album,
			}).toLowerCase();
			return { hash: generatedPath, search: locationSearch };
		}
		return "/";
	}, [isAuth, id, login, containers.modal.path]);

	const inlineStyledMemonized = useMemo(() => {
		return { width: "100%" };
	}, []);

	// ** Hook useMobileDetect
	const mobileDetect = useMobileDetect();
	// ** Sprawdzamy czy użytkownik korzysta z urzadznie z systemem iOS
	const isIos = mobileDetect.isIos();

	// #####################################################

	return (
		<StyledMasonryCardWrapper
			className="noselect"
			ref={lastElementRef}
			minHeight={minHeight}
		>
			<Link
				to={to}
				style={inlineStyledMemonized}
				onClick={() => !isAuth && window.scrollTo(0, 0)}
			>
				{
					<StyledMediaWrapper>
						<div style={{ zIndex: 0 }}>
							<HoverVideoPlayer
								videoSrc={
									<>
										{preview_vertical && (
											<source
												src={preview_vertical}
												type="video/webm"
											/>
										)}
										{sourceFull && (
											<source
												src={sourceFull}
												type="video/mp4"
											/>
										)}
									</>
								}
								playbackStartDelay={100}
								playbackRangeEnd={isIos ? 5 : undefined}
								unloadVideoOnPaused={true}
								preload="none"
								loop
								sizingMode="overlay"
								style={{
									/* MARCIN_P: DZ-1931 - "display:initial" sprawiło, że na Firefoxie na Masonry nie było podglądu filmiku tylko białe tło, prawdopodobnie odczytywało tę właściwość jako "display:inline". 
										Bez display występowały problemy z przerwą między loginem a filmikiem,  dodanie "display:block" pomogło. */
									display: "block",
									overflow: "hidden",
									width: "100%",
								}}
								videoStyle={{
									filter: `${!isAuth ? "blur(7px)" : "none"}`,
								}}
								pausedOverlay={
									<Image
										thumbnail={poster_vertical}
										alt=""
										width={width}
										height={height}
										isMasonry
										withSpinner={withSpinner}
										withoutLazyLoading={withoutLazyLoading}
										iconSize={iconSize}
										withPlayIcon
										isCover
										isSquared={isSquared}
										withRatio_4_3
									/>
								}
							/>
						</div>
					</StyledMediaWrapper>
				}
			</Link>

			<StyledMasonryCardFooterSection asLink={bottomAsLink}>
				{bottomAsLink || bottomAsLinkToMedia ? (
					<Link
						to={
							bottomAsLinkToMedia
								? to
								: `/users/${login.toLowerCase()}`
						}
						style={inlineStyledMemonized}
						onClick={() => {
							if (!bottomAsLinkToMedia) {
								window.scrollTo(0, 0);
							}
						}}
					>
						<MasonryCardFooterContent
							login={login}
							count={likes}
							dateAdd={date_add}
							statusVip={status_vip}
							showZeroCount={false}
							icon={<HeartIcon />}
							isUserGallery={isUserGallery}
						/>
					</Link>
				) : (
					<Block flex justifyEnd fullWidth>
						<MasonryCardFooterContent
							login={login}
							count={likes}
							dateAdd={date_add}
							statusVip={status_vip}
							showZeroCount={false}
							icon={<HeartIcon />}
							isUserGallery={isUserGallery}
						/>
					</Block>
				)}
			</StyledMasonryCardFooterSection>
		</StyledMasonryCardWrapper>
	);
};

export default memo(MasonryVideo);
