// ** Third Party Components
import styled, { css } from "styled-components";

export const StyledMasonryCardWrapper = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;

	box-shadow: ${({ theme: { isDarkMode, utils } }) =>
		isDarkMode
			? utils.createBoxShadow(["cardDark"])
			: utils.createBoxShadow(["cardLight"])};

	${({ minHeight }) =>
		minHeight &&
		css`
			min-height: ${minHeight};
		`};
`;
