// ** React Imports
import { memo, useContext, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

// ** Custom Components
import { Image } from "components/media";
import { ReactComponent as HeartIcon } from "resources/icons/regular/heart.svg";
import { MasonryCardFooterContent } from "./MasonryCardFooterContent";

// ** Utils & Helpers
import generatePath from "utils/generatePath";

// ** Context
import authContext from "contexts/authContext";

// ** Routes
import containers from "containers";

// ** Styled Components
import { StyledMasonryCardWrapper } from "./styles/MasonryCardWrapper.styled";
import { StyledMasonryCardFooterSection } from "./styles/MasonryCardFooterSection.styled";

// #####################################################

const MasonryImage = ({
	images: { vert = "", square = "" } = {},
	date_add,
	likes,
	login,
	status_vip,
	id,
	bottomAsLink,
	bottomAsLinkToMedia,
	album,
	width,
	height,
	type = "photo",
	withSpinner = false,
	withoutLazyLoading = false,
	lastElementRef,
	minHeight,
	isUserGallery = false,
	isSquared,
	locationSearch = "",
}) => {
	const isAuth = useContext(authContext);

	// ** Jeżeli zalogowany generujemy ścieżkę do grafiki,
	// ** w przeciwnym wypadku ustawiamy link na "/"
	const to = useMemo(() => {
		if (isAuth) {
			const generatedPath = generatePath(containers.modal.path, {
				contentId: id,
				login: login?.toLowerCase(),
				type: type,
				album,
			}).toLowerCase();
			return { hash: generatedPath, search: locationSearch };
		}
		return "/";
	}, [isAuth, id, login, containers.modal.path]);

	const inlineStyledMemonized = useMemo(() => {
		return { width: "100%" };
	}, []);

	// #####################################################

	return (
		<StyledMasonryCardWrapper ref={lastElementRef} minHeight={minHeight}>
			<Link
				to={to}
				style={inlineStyledMemonized}
				onClick={() => !isAuth && window.scrollTo(0, 0)}
			>
				<Image
					thumbnail={isSquared ? square : vert}
					isSquared={isSquared}
					alt=""
					width={width}
					height={height}
					zoom
					withSpinner={withSpinner}
					withoutLazyLoading={withoutLazyLoading}
					isCover
					withRatio_4_3
					login={login}
				/>
			</Link>

			<StyledMasonryCardFooterSection asLink={bottomAsLink}>
				{bottomAsLink || bottomAsLinkToMedia ? (
					<Link
						to={
							bottomAsLinkToMedia
								? to
								: `/users/${login.toLowerCase()}`
						}
						style={inlineStyledMemonized}
						onClick={() => {
							if (!bottomAsLinkToMedia) {
								window.scrollTo(0, 0);
							}
						}}
					>
						<MasonryCardFooterContent
							login={login}
							count={likes}
							dateAdd={date_add}
							statusVip={status_vip}
							showZeroCount={false}
							icon={<HeartIcon />}
							isUserGallery={isUserGallery}
						/>
					</Link>
				) : (
					<MasonryCardFooterContent
						login={login}
						count={likes}
						dateAdd={date_add}
						statusVip={status_vip}
						showZeroCount={false}
						icon={<HeartIcon />}
						isUserGallery={isUserGallery}
					/>
				)}
			</StyledMasonryCardFooterSection>
		</StyledMasonryCardWrapper>
	);
};

export default memo(MasonryImage);
